.CvoFlightDetails {
  position: relative;

  &-header {
    display: flex;
    margin-bottom: 10px;
  }

  &-journeyType {
    color: $gray500;
    font-size: 14px;
  }

  &-journeyDetails {
    margin-bottom: 10px;
  }

  &-departureDate {
    font-size: 14px;
    margin-left: auto;
  }

  &-origin {
    align-items: start;
  }

  &-destination {
    align-items: end;
  }

  &-departureStation,
  &-arrivalStation {
    font-size: 30px;
    font-weight: bold;
  }

  &-departure,
  &-arrival {
    color: $gray500;
    font-size: 16px;
    text-transform: uppercase;
  }

  &-divider {
    border-color: $gray400;

    &::after,
    &::before {
      border-color: $gray500;
    }
  }

  &-flightInfo {
    color: $gray500;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
}

.CvoFlightDetails--confirmation {
  .CvoFlightDetails {
    &-origin,
    &-destination {
      flex-direction: column-reverse;
    }

    &-departure,
    &-arrival {
      color: $black;
      @include text-base(24px, 700);
    }

    &-departureStation,
    &-arrivalStation {
      color: $black;
      @include text-base(14px);
    }

    &-journeyDetails {
      margin-bottom: 0;
    }

    &-divider {
      width: 257px;
      @include mobile {
        width: 100%;
      }
    }

    &-flightInfo {
      justify-content: unset;
      flex-flow: column;
      margin-top: -10px;
      span:first-of-type {
        align-self: center;
      }
    }
  }
}