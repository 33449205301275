.CvoHeader {
  &-link {
    border: 1px solid var(--gray400);
    color: var(--black);
    padding: 13.5px 14px;
    border-radius: 10px;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 44px;
    @include text-base(14px, 700);
    &::before {
      display: inline-block;
      margin-right: 10px;
      font-family: var(--icons-font);
      font-size: 26px;
      vertical-align: bottom;
    }

    &:nth-child(1):before {
      content: '\e904';
      transform: rotate(-45deg);
    }

    &:nth-child(2):before {
      content: '\e913';
    }

    &:nth-child(3):before {
      content: '\e91a';
    }

    &:nth-child(4):before {
      content: '\e90d';
    }

    &:nth-child(5) {
      text-align: center;
      background-color: $primary;
      border-radius: 25px;
      color: white;
    }
  }

  &-userPanel {
    &-top {
      margin-bottom: 24px;
    }

    hr, .CvoHeader-userAreaButton, .CvoHeader-logoutButton {
      display: none;
    }
  }

  &-userLinks {
    display: flex;
    flex-flow: column;
    gap: 24px;
  }

  @include mobile {
    &-userPanel {
      bottom: unset;
    }
  }
}
