.RedeemConfirmationPage {
  &-Vplus {
    .PageContainer-error {
      .CvoPurchaseConfirmation-successMessage:before {
        color: $danger;
        content: '\e917';
      }
    }
    .CvoPurchaseConfirmation {
      &-background {
        justify-content: flex-start;
        @include mobile {
          background-image: none !important;
          padding: 0;
          margin-bottom: 80px;
          .CvoPurchaseConfirmation-container {
            margin-top: 32px;
          }
        }
      }

      &-successMessage {
        font-size: 36px;
        margin-bottom: 10px;
        @include mobile {
          @include text-base(16px, 900);
        }
      }

      &-successMessage:before {
        font-size: 20px;
        @include mobile {
          @include text-base(14px);
        }
      }

      &-title {
        text-align: center;
        @include mobile {
          @include text-base(14px);
        }
      }

      &-text {
        font-weight: 600;
        color: #5794CF;
      }

      &-price {
        width: 100%;
        margin: 15px 0;
        text-align: left;
        line-height: unset;
      }

      &-price-recurrency,
      &-priceRecurrency {
        text-align: left;
        @include text-base(14px);
      }

      button::after {
        content: '\e902';
        font-family: var(--icons-font);
        font-size: 24px;
        margin-left: 5px;
      }
    }

    .InformationCard {
      border-radius: 10px;
    }

    button {
      margin: 0 auto;
      height: 50px;
    }

    .RedeemConfirmationPage {

      &-flight {
        .CvoInformationCard {

          &-title {
            margin-bottom: 0;
          }

          &-informationWrapper {
            flex-basis: 65%;
            > p {
              font-weight: 600;
              color: #5794CF;
            }
          }

          &-extrasWrapper {
            flex-basis: 35%;
          }
        }
      }

      &-passenger {
        .CvoInformationCard {

          &-content:first-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    .RouteTitle {
      margin-bottom: 20px;
      @include mobile {
        display: flex;
        flex-wrap: wrap;
      }

      &:before {
        content: '\e94c';
        color: $primary;
        font-family: var(--icons-font-2);
        font-size: 22px;
        margin-right: 10px;
      }

      &-date {
        display: inline-block;
        margin-right: 10px;
        @include mobile {
          flex-grow: 1;
        }
      }

      &-route {
        font-weight: normal;
      }

      &-departureStation {
        &:after {
          content: '\e900';
          color: $primary;
          font-family: var(--icons-font-2);
          font-size: 18px;
          margin: 0px 12px;
        }
      }
    }
  }
}
