$el: '.CvoVerticalPlan';

.Landing-piece {
  .CvoVerticalPlan {
    width: 289px;
    box-shadow: rgba(51, 51, 51, 0.25) 0 2px 12px 0;

    &-container {
      margin-top: -166px;
      display: flex;
      flex-flow: column;
      height: calc(100% + 18px);
    }

    &-title {
      color: white;
      @include text-base(24px);
      height: 142px;
      margin: 0 auto 24px;
      padding-top: 50px;
      .price {
        display: block;
        @include text-base(40px, 900);
        font-weight: 700;
        letter-spacing: -3px;
        .currency {
          @include text-base(24px);
        }
      }
    }

    &-shortDescription {
      @include text-base(16px);
      height: unset;
    }

    button {
      @include button-primary;
      background-color: $purple;
      border-color: $purple;
      color: $white;
    }

    &-fixedContent {
      width: 100%;
      align-self: flex-end;
      margin-top: auto;
    }

    &.non-selectable {
      #{$el}-title {
        @include text-base(16px);
        color: var(--primary)
      }
    }

    &:last-of-type {
      @include mobile {
        margin-right: auto;
      }
    }
  }

  .CvoVerticalPlan + .CvoVerticalPlan {
    #{$el}-container {
      margin-top: unset;
      height: calc(100% - 166px + 18px);
    }

    #{$el}-title {
      height: unset;
      padding-top: unset;
      @include text-base(24px, 700);
      color: $primary;
      margin-bottom: 0;
    }
  }
}
