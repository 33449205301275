$el: '.CvoHeroImage';

@mixin hero-container {
  @include hero-button;

  h1 img {
    filter: brightness(0) invert(1);
    max-width: 333px;
  }

  h2 {
    display: none;
  }

  p {
    width: unset;
    margin-top: 20px;
    color: white;
    @include text-base(24px);
    @include mobile {
      text-align: center;
    }
    b {
      @include text-base(40px, 900);
      display: block;
      .currency {
        @include text-base(24px);
      }
    }
  }

  button {
    display: block;
    @include button-secondary;
    border-color: $white;
    &:active,
    &:focus {
      background-color: $light-purple;
      border-color: $light-purple;
      color: $purple;
    }
    @include mobile {
      width: 100%;
      margin-top: 2em;
    }
  }
}

@mixin hero-button {
  button {
    border-radius: 20px;
    color: var(--primary);
    background-color: white;
    @include text-base(14px);
    height: 40px;
    padding: 0 35px;
  }
}

.Landing-piece.Hero-wrapper {
  padding: 3em 0 0;
  .CvoHeroImage {
    padding: 0;
    max-width: 100%;
  }
}

.Hero-container-mobile {
  display: none;
  background-color: var(--primary);
  padding: 1em 2em;
  @include hero-container;
  @include mobile {
    display: block;
  }
}

.Header ~ article.CvoHeroImage {
  @include mobile {
    margin-top: 0px;
  }
}

.CvoHeroImage {
  &::before {
    background: none;
  }

  &-background {
    padding: 20px 138px;
    min-height: unset;
    background-position: bottom;
    @media screen and (min-width: 1500px) {
      background-position: top;
    }
    @include tablet-down {
      padding-left: 20px;
    }
    @include mobile {
      height: 300px;
      padding: 0;
      background-position: right -50px bottom 0px;
    }
    @media screen and (max-width: 625px) {
      background-position: right -140px bottom 0px;
    }
    @media screen and (max-width: 525px) {
      background-position: right -230px bottom 0px;
    }
    #{$el}-container {
      @media screen and (min-width: 1500px) {
        margin-left: 10%;
      }
      @include mobile {
        display: none;
      }
      width: 333px;
      margin: auto;
      margin-left: 0;
      @include hero-container;
    }
  }

  &-countdown {
    h3 {
      background-color: #00cd0a;
      color: white;
      @include text-base(16px);
      line-height: 45px;
      font-weight: 700;
      width: 318px;
      height: 45px;
      text-align: center;
      border-radius: 2px;
      margin-top: 32px;
      margin-bottom: 24px;
      @include mobile {
        margin: 32px auto 24px;
      }
    }

    div {
      text-align: center;
      p:nth-child(1) {
        @include text-base(40px);
        font-weight: 900;
        letter-spacing: 1px;
      }
      p:nth-child(2) {
        @include text-base(16px);
        font-weight: 900;
        display: flex;
        gap: 22px;
        justify-content: center;
        margin-top: 0px;
      }
    }
  }

  @include hero-button;
}
