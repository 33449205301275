.CvoModal {
  .vm--modal {
    border-radius: 8px;
  }
  &.CancelPlan-modal {
    .vm--modal {
      left: unset !important;
      width: unset !important;
      top: unset !important;
    }
  }
}
