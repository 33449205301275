@mixin secondary-info-icon($size) {
  background: var(--volarisInfoImageURL);
  width: $size;
  height: $size;
  content: '';
  filter: brightness(0) saturate(100%) invert(43%) sepia(20%) saturate(1178%) hue-rotate(168deg) brightness(96%) contrast(90%);
}

@mixin warn-icon($size) {
  background: var(--volarisWarnImageURL);
  width: $size;
  height: $size;
  content: '';
  filter: unset;
}
