.CvoAutocomplete {
  .autocomplete ul.autocomplete-result-list {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-color: $gray300;
    border-top-color: transparent;
    margin-top: -15px;
  }

  &-clearButton {
    position: absolute !important; // override v0 styles
  }
}