.CvoNavigation {
  background: $white;
  box-shadow: $section-shadow;
  padding: 20px;
  border-radius: 10px;

  &-nav {
    display: flex;
    flex-direction: column;
  }

  &-item {
    border: 1px solid $gray400;
    color: $black;
    padding: 20px 14px;
    border-radius: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    font-weight: bold;

    &.is-active {
      background-color: $secondary-button-background;
      border: 2px solid $primary;
      color: $primary;
    }

    &:before {
      font-family: $icons-font;
      font-size: 26px;
      vertical-align: bottom;
    }

    &:nth-child(1):before {
      content: '\e913';
    }

    &:nth-child(2):before {
      content: '\e91a';
    }

    &:nth-child(3):before {
      content: '\e90d';
    }

    &:nth-child(4):before {
      content: '\e90d';
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}