.CvoSearchLastMinute {
  background: $white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow-lg;
  padding: 24px;

  &-toggle.CvoToggleButton {
    display: inline-block;
    margin-bottom: 10px;

    @include mobile {
      width: 100%;
    }

    .CvoToggleButton {
      &-buttonsContainer {
        border: 1px solid $gray200;
        border-radius: $border-radius;
        justify-content: unset;
        padding: 3px;
      }

      &-label {
        border: none;
        color: $gray400;
        margin: 0;
        max-width: auto;
        min-height: auto;
        padding: 4px 18px;

        &.is-selected {
          background: $primary;
          box-shadow: none;
          color: $white;

          &::after {
            content: none;
          }
        }
      }

      &-title {
        font-weight: normal;
        margin: 0,
      }
    }
  }

  &-row:nth-child(2) {
    display: flex;
    gap: 24px;

    @include mobile {
      flex-direction: column;
      gap: 12px;
    }

    .CvoAutocomplete {
      flex: 1;

      label {
        margin-bottom: 5px;
      }

      p.CvoForm-field--error {
        line-height: 1;
        margin-top: 5px;
        min-height: 12px;
      }
    }
  }

  &-row:nth-child(3) {
    align-items: flex-end;
    display: flex;
    @include mobile {
      flex-direction: column;
      gap: 12px;
      .CvoSearchLastMinute-submitWrapper {
        width: 100%;
        button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  &-controlsWrapper {
    align-items: flex-end;
    display: flex;
    flex: 1;

    .CvoRadio {
      &-controlsContainer {
        display: flex;
        gap: 5px;
      }
    }
  }

  &-disclaimer {
    color: $gray500;
    font-size: 12px;

    &::before {
      @include secondary-info-icon(14px);
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      margin-bottom: 3px;
    }
  }

  &-submit {
    @include button-cta;
  }
}
