@mixin button-base {
  border-radius: 100px;
  padding: 16px 28px;
  border-style: solid;
  border-width: 1px;
  height: 53px;
  @include text-base(16px);

  &[disabled],
  &.disabled {
    background-color: $gray;
    border-color: $gray;
    color: $dark-gray;
    pointer-events: none;
    user-select: none;
  }

  &:active,
  &:focus,
  &:focus:not(:active) {
    box-shadow: none;
    outline: none;
  }
}

@mixin button-primary {
  @include button-base;

  background-color: $light-purple;
  border-color: $light-purple;
  color: $purple;

  &:hover {
    background-color: $dark-purple;
    border-color: $dark-purple;
    color: $white;
  }

  &:active,
  &:focus {
    background-color: $purple;
    border-color: $purple;
    color: $white;
  }
}

@mixin button-cta {
  @include button-base;

  background-color: $primary;
  border-color: $primary;
  color: $white;

  &:hover {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }

  &:active,
  &:focus {
    background-color: $dark-purple;
    border-color: $dark-purple;
    color: $white;
  }
}

@mixin button-secondary {
  @include button-base;

  background-color: $white;
  border-color: $purple;
  color: $purple;

  &:hover {
    background-color: $light-purple;
    border-color: $light-purple;
  }

  &:active,
  &:focus {
    background-color: $purple;
    border-color: $purple;
    color: $white;
  }
}

.ButtonCta {
  @include button-cta;
}