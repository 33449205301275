.PrivatePage {
  padding-top: 30px;

  &-mainContent {
    > h1 {
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 20px;
    }

    > h2 {
      font-size: 24px;
      margin-bottom: 18px;
    }
    
    p {
      font-size: 14px;
    }
  }
}