.CvoSimpleCart {
  &.is-empty {
    .CvoSimpleCart-section--main {
      display: none;
    }
  }

  &-itemName {
    color: $black;
  }

  &-collapseButton {
    background-color: transparent;
  }

  &-arrow {
    border: none;
    background: url(https://volaris.caravelo.com/images/arrow-down.svg) no-repeat;
    height: 20px;
    width: 20px;
    transform: scale(2);
    background-position: center center;
  }

  &-arrow--up {
    transform: scale(2) rotate(180deg);
  }
}
