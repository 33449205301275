.Landing-piece {
  ul, ol {
    @include text-base(16px);
    list-style: none;
    li {
      break-inside: avoid-column;
      a {
        font-weight: 900;
        text-decoration: underline;
      }
      & + li {
        margin-top: 16px;
      }
      &::before {
        color: var(--primary);
        width: 24px;
        font-weight: 900;
        display: inline-block;
      }
    }
  }

  ol {
    counter-reset: li;
    li::before {
      content: counter(li)'.';
    }
    li {
      counter-increment: li;
    }
  }

  ul li {
    text-indent: -16px;
    &::before {
      content: '⬤';
      width: 16px;
    }
    & + li {
      margin-top: 24px;
    }
  }

  iframe {
    display: block;
    margin: 56px auto 0;
    width: 634px;
    height: 356px;
    @include mobile {
      width: 350px;
      height: 197px;
    }
  }
}

.Travel-experiences-card {
  display: flex;
  height: 250px;
  width: 761px;
  padding: 0;
  background-color: white;
  border-radius: 5px;

  @include mobile {
    flex-flow: column-reverse;
    width: 95%;
    max-width: 400px;
    height: unset;
  }

  img {
    border-radius: 5px 0 0 5px;
    @include mobile {
      border-radius: 0 0 5px 5px;
    }
  }

  ol {
    margin: auto;
    align-self: center;
    padding: 0 24px;
    @include mobile {
      padding: 24px;
    }
  }
}

.More-information {
  column-count: 2;
  column-gap: 60px;
  @include mobile {
    column-count: 1;
    padding-left: calc(1em + 34px);
  }
}
