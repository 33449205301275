.CvoSearchResult {
  margin-bottom: 40px;

  .CvoCarouselSlider {
    box-shadow: $box-shadow;
    border-radius: $border-radius-lg;
    overflow: hidden;

    &-carousel {
      padding: 0px 50px;
    }

    &-container {
      margin-left: -2px;
      margin-right: -5px;
    }

    &-slide {
      padding: 10px 0px;

      &:not(.CvoFlightDateSelector-disabled) {
        cursor: pointer;
      }

      &:first-child {
        .CvoCarouselSlider-slideText {
          border-left: 2px solid $gray300;
        }
      }

      &.CvoFlightDateSelector-selectedDate {
        font-weight: 700;
      }
    }

    &-slideInner {
      background-color: transparent;
      border: none;
      height: auto;
    }

    &-slideText {
      border-right: 2px solid $gray300;
      font-size: 14px;
      line-height: 1.5;
      padding: 5px 8px;
      text-align: center;
    }

    &-controlSvg {
      width: 40%;
      height: 40%;
    }

    &-control--prev {
      left: 8px;
    }

    &-control--next {
      right: 8px;
    }
  }

  .CvoFlightDateSelector {
    margin-bottom: 20px;

    &-disabled {
      background-color: $white;

      p {
        color: $gray400;
      }
    }

    &-selectedDate {
      background-color: $light-purple;
      border: 2px solid $primary;
      border-radius: $border-radius-lg;
      margin: 0 -1px;
      position: relative;
      z-index: 2;

      p {
        background-color: transparent;
        color: $primary;
      }
    }
  }

  .CvoCollapsibleDirectFlightRow {
    background-color: $white;
    border-radius: 10px;
    box-shadow: $box-shadow;

    @include mobile {
      flex-flow: column;
    }

    &-col {
      flex: none;
    }

    &-content {
      display: flex;
      flex: 1;
      gap: 20px;
      padding: 25px 25px 55px 25px;
      position: relative;
      @include tablet {
        border-right: 1px solid $gray300;
      }
      @include mobile {
        border-bottom: 1px solid $gray300;
      }
    }

    &-actions {
      padding: 25px;
    }

    &-departure > span,
    &-arrival > span {
      text-align: center;
    }

    &-hour {
      display: block;
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-city {
      display: block;
      font-size: 14px;
    }

    &-citiesSeparator {
      display: flex;
      flex: 1;
      flex-direction: column;
      text-align: center;
    }

    &-arrow {
      display: block;
      border: 0.5px solid $gray400;
      order: 2;
      position: relative;

      &::before,
      &::after {
        background-color: $gray400;
        border: none;
        border-radius: 50%;
        content: '';
        display: block;
        height: 6px;
        position: absolute;
        top: -3px;
        width: 6px;
      }

      &::before {
        left: -3px;
      }

      &::after {
        right: -3px;
      }
    }

    &-stops {
      font-size: 14px;
      order: 3;
    }

    &-duration {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      order: 1;
    }

    &-priceWrapper {
      display: none;
    }

    &-carrierContainer {
      bottom: 20px;
      position: absolute;
      left: 25px;
      @include mobile {
        left: 0px;
        width: 100%;
        text-align: center;
      }
    }

    &-flightCode,
    &-carrier {
      color: $gray400;
      display: inline-block;
      font-size: 14px;
      margin-right: 5px;
    }

    &-customPriceContainer {
      font-size: 16px;
      margin-bottom: 8px;
    }

    &-customPrice {
      font-weight: bold;
    }

    &-actions {
      text-align: center;
    }

    &-action {
      @include button-primary;
      @include mobile {
        width: 100%;
        justify-content: center;
      }
    }
  }

  .SelectedFlight {
    background-color: $white;
    border-left: 8px solid $primary;
    border-radius: 10px;
    box-shadow: $box-shadow;
    display: flex;
    padding: 25px;

    &-col:not(:last-child) {
      flex: 1;
    }

    &-title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &-description {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &-route {
      align-items: center;
      display: flex;
    }

    &-routeWrapper {
      text-align: center;
    }

    &-routeTime {
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-routeCity {
      font-size: 14px;
    }

    &-routeDivider {
      display: block;
      border: 0.5px solid $gray400;
      height: 0px;
      position: relative;
      min-width: 50px;
      margin: 0 20px;

      &::before,
      &::after {
        background-color: $gray400;
        border: none;
        border-radius: 50%;
        content: '';
        display: block;
        height: 6px;
        position: absolute;
        top: -3px;
        width: 6px;
      }

      &::before {
        left: -3px;
      }

      &::after {
        right: -3px;
      }

      @include mobile {
        flex-grow: 1;
      }
    }

    &-info {
      color: $gray400;
    }

    &-action {
      font-size: 14px;
    }

    &-selectedIcon {
      width: 20px;
      height: 20px;
      display: inline-block;
      background-color: $success;
      background: var(--volarisCheckImageURL);
      background-position: center;
      border-radius: 10px;
      margin-right: 10px;
    }

    .SelectedFlight-col:nth-child(1) {
      flex-basis: 50%;
    }

    .SelectedFlight-col:nth-child(2) {
      flex-basis: 30%;
    }
    .SelectedFlight-col:nth-child(3) {
      flex-basis: 20%;
      text-align: right;
    }

    @include mobile {
      flex-flow: column;
      padding: 12px;
      &-route-divider {
        flex-grow: 1;
      }
      .SelectedFlight-col:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        h2 {
          order: 1;
          flex-basis: 100%;
          margin-bottom: 0px;
          margin-top: 32px;
        }
        p {
          text-align: center;
          flex-basis: 100%;
        }
      }
      .SelectedFlight-col:nth-child(3) {
        text-align: right;
        margin-top: -27px; // Needed because it has to be in line with the h2 from the previous div :(
      }
    }
  }
}

.PrivatePage-mainContent {
  .CvoSearchResult-noResults {
    display: flex;
    gap: 24px;

    .icon {
      width: 124px;
      height: 124px;
      transform: rotate(45deg);
      #Shape {
        fill: $lightblue;
      }
    }

    h2 {
      @include text-base(40px, 900);
      color: $lightblue;
    }

    p {
      @include text-base(24px, 700);
      color: $gray600;
    }
  }
}
