.CvoVideo {
  > iframe {
    display: block;
    margin: 56px auto 0;
    @include mobile {
      width: 350px;
      height: 197px;
    }
  }
}
