$el: '.CvoDirectFlightRow';

.CvoDirectFlightRow {
  &-col {
    flex: auto;
  }

  &-hour {
    margin-right: 8px;
  }

  &-flightCode {
    font-weight: bold;
  }

  &.LegSummary {
    gap: 20px;
    
    &::before {
      content: '\e904';
      font-family: var(--icons-font);
      display: inline-block;
      height: 24px;
      margin-right: 12px;
      width: 24px;
    }

    .CvoDirectFlightRow-citiesContainer {
      display: flex;
      wrap: nowrap;

      >span {
        display: flex;
        wrap: nowrap;
      }
    }

    span > span {
      display: inline-block;
      color: gray;
      font-weight: 600;
      margin-right: 12px;
      text-transform: none;

      @include mobile {
        font-size: 16px;
      }
    }

    #{$el}-city {
      margin-right: 0;
    }

    #{$el}-hour {
      @include mobile {
        display: block;
      }
    }

    #{$el}-arrow {
      background: url('../images/arrow-right.svg') no-repeat center;
      background-size: contain;
      display: inline-block;
      height: 16px;
      width: 49px;

      @include mobile {
        height: calc(16px * 0.5);
        width: calc(49px * 0.5);
      }

      &::before {
        content: none;
      }
    }
  }

  &.LegDetails {
    max-width: 60%;
    
    @include mobile {
      max-width: 100%;
    }

    // Custom media query as it breaks in smaller screens
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }
}