.MexicanResident {
  &-question {
    background-color: $white;
    border-radius: $border-radius-lg;
    padding: 24px;
    margin: 32px 0 64px;

    .CvoRadio {
      &-controlsContainer {
        display: flex;
      }

      [type="radio"] + label {
        @include text-base(14px);
        color: $gray600;
        line-height: 24px;
        display: flex;
        padding-left: 32px;

        &::before {
          width: 24px;
          height: 24px;
        }
      }
    }

    div + div.Notecard {
      margin-top: 16px;
    }

    .CvoModal {
      .vm--modal {
        padding: 40px 12px 16px;
        max-width: 400px;
      }

      &-title {
        @include text-base(24px, 700);
        color: $black;
        padding: 8px 20px;
      }

      &-content {
        padding: 0 20px;
        margin-top: 12px;

        ul, p {
          @include text-base(16px);
          color: $gray600;
          text-align: left;
        }

        ul {
          list-style: disc inside;
        }

        p {
          margin-top: 16px;
        }

        button {
          font-size: 14px;
          height: unset;
          margin: 24px auto;
          padding: 8px 20px;
        }
      }
    }
  }
}
