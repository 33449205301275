.Notecard {
  background-color: $white;
  border-radius: $border-radius;
  color: $gray600;
  font-size: 14px;
  padding: 16px 12px 16px 42px;
  position: relative;

  &, &-info {
    border: 2px solid $secondary;
    border-left-width: 6px;

    &::before {
      @include secondary-info-icon(18px);
      display: inline-block;
      font-size: 18px;
      left: 16px;
      margin-right: 5px;
      position: absolute;
      top: 16px;
      margin-top: 3px;
    }
  }

  &-warn {
    border-color: $warn;
    &::before {
      @include warn-icon(18px);
    }
  }

  &--mexican-resident {
    @include text-base(14px);
    color: $gray600;
    padding: 16px 16px 16px 60px;

    &::before {
      @include secondary-info-icon(22px);
      top: calc(50% - 12px);
    }

    &.Notecard-warn {
      &::before {
        @include warn-icon(22px);
      }
    }

    + * {
      margin-top: 20px;
    }
  }
}
