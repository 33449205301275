.CvoTiles {
  &-tile {
    span:first-child {
      display: block;
      width: 96px;
      height: 96px;
      margin: auto;
      background-color: $gray;
      border-radius: 96px;
      margin-bottom: 24px;
      img {
        padding: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
