.PrivatePage-availability {
  display: flex;
  .is-three-quarters > div > div > h2 {
    @include text-base(24px);
  }
  .is-three-quarters button.ButtonCta .icon {
    transform: scale(0.5);
    margin-left: 24px;
    #Shape {
      fill: white;
    }
  }
  @include mobile {
    flex-flow: column-reverse;
    .is-three-quarters button.ButtonCta {
      width: 100%;
      justify-content: center;
      margin-bottom: 32px;
    }
  }
}

.availabilityDocUrlContainer {
  color: $gray600;
  margin-top: 30px;
}

.availabilityDocUrlContainer > a {
  color: $lightblue;
  font-weight: bold;
  text-decoration: underline;
}

.availabilityDocIframeContainer {
  height: 730px;
  margin-bottom: 30px;
}

.SearchFlightLoader {
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  &-content {
    background-color: transparent;
    padding: 20px;
    position: fixed;
    top: 150px;
    width: 50%;
    left: 25%;
    img {
      object-fit: cover;
      // This crops a few px of the right side of the image, which is empty and makes it look odd
      width: calc(114%);
      max-width: unset;
    }
    @include mobile {
      width: 90%;
      left: 5%;
    }
  }

  &-title {
    @include text-base(24px, 700);
    border-bottom: 2px solid #a12885;
    color: $black;
    display: inline-block;
    padding-bottom: 24px;
    width: 100%;
  }
}
