.CvoNavigationProfile {
  @include mobile {
    display: none;
  }

  &-item:nth-child(1):before {
    content: '\e904';
    transform: rotate(-45deg);
  }

  &-item:nth-child(2):before {
      content: '\e913';
  }

  &-item:nth-child(3):before {
      content: '\e91a';
  }

  &-item:nth-child(4):before {
      content: '\e90d';
  }
}
