$primary: #a12885;
$secondary: #4576a5;
$lightblue: #5794CF;
$gray: #EAEAEA;
$dark-gray: #BCBCBC;
$gray050: #f7f7f7; /* lighter bg color */
$gray100: #f0f0f0; /*bg color*/
$gray200: #ebebeb; /*stepbar bg color*/
$gray300: #e5e5e5; /*input border color*/
$gray400: #bcbcbc; /*stepbar item color*/
$gray500: #7f7f7f; /*cart item color*/
$gray600: #666666; /*disclaimer search component*/
$danger: #f14668;
$success: #2E7D32;
$success10: #467e3340;
$white: #ffffff;
$black: #000000;
$light-purple: #F6EEF5;
$purple: $primary;
$dark-purple: #762967;
$secondary-background: #f5f8fa;
$secondary-button-background: #f3e3ed;
$warn: #ffb800;
$max-width: 1152px;
$buttons-width: 350px;
$buttons-height: 58px;
$icons-font: 'icomoon' !important;

$border-radius: 8px;
$border-radius-lg: 16px;

$section-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
$box-shadow-lg: 0px 2px 12px rgba(51, 51, 51, 0.25);
$box-shadow: 0px 1px 8px 2px rgba(51, 51, 51, 0.15);

$header-height: 54px;

// Size
$tablet: 769px !default;
$desktop: 1024px !default;
$header-height: 60px;

:root {
  --max-width: 1280px;
}
